import { createSlice } from "@reduxjs/toolkit";

export const certificationSlice = createSlice({
  name: "certification",
  initialState: {
    username: null,
    date: null,
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const { setUsername, setDate } = certificationSlice.actions;

export default certificationSlice.reducer;
