import "../assets/styles/Index.css";
import React from "react";
import favicon from "../assets/images/favicon.png";
import Helmet from "react-helmet";
import store from "../store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import PrivatePage from "./PrivatePage";

let persistor = persistStore(store);

const AppPage = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            http-equiv="Content-Security-Policy"
            content="upgrade-insecure-requests"
          />
          <link rel="icon" href={favicon} />
          <title>R+R Certification</title>
        </Helmet>
        <PrivatePage className="font-sans">{children}</PrivatePage>
      </PersistGate>
    </Provider>
  );
};

export default AppPage;
