import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    email: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setEmail: (state, action) => {
        state.email = action.payload;
    }
  },
});

export const { setToken,setEmail } = authSlice.actions;

export default authSlice.reducer;
