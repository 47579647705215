import axios from "axios";

axios.defaults.baseURL = "https://backend.rplusrprogram.com";

export const requestInterceptor = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}` || null;
  axios.interceptors.request.use(
    (req) => {
      return req;
    },
    (err) => {
      return err;
    }
  );

  // For POST requests
  axios.interceptors.response.use(
    (res) => {
      // Add configurations here
      if (res.status === 201) {
      }
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};
