import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectToken } from "../reducers/authSlice";
import { requestInterceptor } from "../services/requestInterceptor";

const PrivatePage = ({ children }) => {
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    if (!token) navigate("/");
  }, []);

  requestInterceptor(token);
  return <div>{children}</div>;
};

export default PrivatePage;
